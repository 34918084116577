import React from 'react'
import TrainingTemplate from 'components/templates/TrainingTemplate/TrainingTemplate'

export default function TrainingFirePreventionPage() {
  const seoKeywords = ['SEO0', 'SEO1', 'SEO2']

  const productName = 'Antincendio'

  const description =
    'Il decreto ministeriale 10 marzo 1998, di attuazione dell’art. 13 del D.L.vo 626/94, impone l’obbligo a <b>tutte le aziende con più di 10 dipendenti</b> di effettuare un’adeguata valutazione del rischio di incendio sui luoghi di lavoro, di redigere un piano di emergenza e di provvedere successivamente alla programmazione e attuazione delle misure preventive necessarie : installazione cartellonistica, impianti di allarme, mezzi di estinzione, esercitazioni,… . Tutte queste attività devono essere naturalmente accuratamente documentate.<br> <b>ING GROUP</b> affianca il datore di lavoro nella valutazione dell’adeguatezza della situazione aziendale in merito alla prevenzione incendi, produrre la documentazione e suggerire gli interventi necessari.<br> Ad oggi abbiamo curato la progettazione antincendio e l’ottenimento del CPI (Certificazione di Prevenzione Incendi) per molteplici aziende nei settori produttivi, dei servizi e delle attività ricettive ed alberghiere su tutto il territorio nazionale, inclusi tutti i rapporti con i locali comandi dei Vigili del fuoco.<br> Il decreto impone anche che tutti i dipendenti vengano informati sui rischi di incendio presenti sul luogo di lavoro, inoltre il datore di lavoro ha l’obbligo di incaricare uno o più dipendenti della prevenzione incendi, tali addetti devono seguire un corso di formazione, comprensivo di esercitazioni e conforme per durata e contenuti a quanto previsto dal Decreto :<br><br> <ul><li>4 ore per aziende a basso rischio di incendio;</li> <li>8 ore per aziende a medio rischio di incendio;</li> <li>16 ore per aziende ad alto rischio di incendio.</li></ul><br> <b>ING GROUP</b> organizza tali corsi presso le proprie sedi o, a richiesta, presso l’azienda.'

  const description1 = null

  return (
    <>
      <TrainingTemplate
        seoKeywords={seoKeywords}
        productName={productName}
        description={description}
        description1={description1}
      />
    </>
  )
}
